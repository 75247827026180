.highlight {
	position: relative;
	overflow: hidden;
	padding: 24px 0;

	@include minW(640) {
		padding: 48px 0;
	}

	@include minW(992) {
		padding: 32px 0;
	}

	@include minW(1600) {
		padding: 48px 0;
	}

	&__body {
		background-color: $white-color;
		padding: 16px;
		border-radius: 20px;
		background: rgba(255, 247, 247, 0.7);
		border: 1px solid #FFFFFF;
		box-shadow: 4px 4px 50px rgba(107, 114, 128, 0.2);

		@include minW(640) {
			padding: 24px;
			border-radius: 40px;
		}

		@include minW(992) {
			padding: 70px 112.5px;
			border-radius: 45px;
		}

		@include minW(1600) {
			padding: 70px 140px;
			border-radius: 60px;
		}
	}

	&__row {
		@include minW(992) {
			display: flex;
			align-items: center;
			margin: 0 -60px;
		}
	}

	&__col {
		@include minW(992) {
			flex: 0 0 auto;
			width: 50%;
			padding: 0 60px;
		}
	}
}
