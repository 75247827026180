.title {
	font-family: $primary-font;
	font-size: 12px;
	font-weight: 800;
	line-height: 1.36;
	color: $red-color;
	a {
		color: $red-color;
	}
	@include minW(640) {
		font-size: 16px;
	}

	@include minW(992) {
		font-size: 15px;
	}

	@include minW(1600) {
		font-size: 20px;
	}

	span {
		display: block;
	}

	&--1 {
		font-size: 32px;

		@include minW(640) {
			font-size: 48px;
		}

		@include minW(992) {
			font-size: 60px;
		}

		@include minW(1600) {
			font-size: 80px;
		}
	}

	&--2 {
		font-size: 30px;

		@include minW(640) {
			font-size: 40px;
		}

		@include minW(992) {
			font-size: 52.5px;
		}

		@include minW(1600) {
			font-size: 70px;
		}
	}

	&--3 {
		font-size: 24px;

		@include minW(640) {
			font-size: 32px;
		}

		@include minW(992) {
			font-size: 45px;
		}

		@include minW(1600) {
			font-size: 60px;
		}
	}

	&--4 {
		font-size: 24px;

		@include minW(640) {
			font-size: 28px;
		}

		@include minW(992) {
			font-size: 38px;
		}

		@include minW(1600) {
			font-size: 50px;
		}
	}

	&--5 {
		font-size: 20px;

		@include minW(640) {
			font-size: 24px;
		}

		@include minW(992) {
			font-size: 30px;
		}

		@include minW(1600) {
			font-size: 40px;
		}
	}

	&--6 {
		font-size: 16px;

		@include minW(640) {
			font-size: 20px;
		}

		@include minW(992) {
			font-size: 22.5px;
		}

		@include minW(1600) {
			font-size: 30px;
		}
	}

	&--white {
		color: $white-color;
	}

	&--uppercase {
		text-transform: uppercase;
	}
}
