$primary-font: 'Manrope',
sans-serif !default;

$icon-font: 'icomoon',
sans-serif !default;

$white-color: #fff !default;
$black-color: #000 !default;
$red-color: #C32314 !default;
$grey-color: #A6A6A6 !default;
