.footer {
	position: relative;
	overflow: hidden;
	border-top: 4px solid $red-color;
	padding: 32px 0;
	width: 100%;
	background-color: $white-color;

	@include minW(640) {
		padding: 64px 0;
	}

	@include minW(992) {
		padding: 48px 0;
	}

	&__main {
		width: 100%;
		padding: 0 16px;
		margin: 0 auto;

		@include minW(640) {
			padding: 0 32px;
		}

		@include minW(992) {
			padding: 0 16px;
			max-width: 1632px;
		}
	}

	&__body {
		margin-bottom: 32px;

		@include minW(640) {
			display: flex;
			margin-bottom: 64px;
		}

		@include minW(992) {
			margin-bottom: 112px;
		}
	}

	&__logo {
		display: block;
		margin-bottom: 32px;

		@include minW(640) {
			flex-basis: 40%;
			max-width: 40%;
		}

		@include minW(992) {
			flex-basis: 24%;
			max-width: 24%;
			margin-bottom: 0;
		}

		@include minW(1600) {
			flex-basis: 34%;
			max-width: 34%;
		}

		.img {
			width: 150px;

			@include minW(640) {
				width: 210px;
			}

			@include minW(992) {
				width: 210px;
			}

			@include minW(1600) {
				width: 278px;
			}
		}
	}

	&__content {
		@include minW(640) {
			flex-basis: 60%;
			max-width: 60%;
		}

		@include minW(992) {
			flex-basis: 76%;
			max-width: 76%;
		}

		@include minW(1600) {
			flex-basis: 66%;
			max-width: 66%;
		}
	}

	&__title {
		font-size: 18px;
		color: $red-color;
		margin-bottom: 16px;
		a {
			color: $red-color;
			&:hover {
				text-decoration: underline;
			}
		}
		@include minW(640) {
			font-size: 20px;
		}
	}

	&__row {
		@include minW(992) {
			display: flex;
			margin: 0 -18px;
		}

		@include minW(1600) {
			margin: 0 -24px;
		}
	}

	&__col {
		display: flex;
		flex-direction: column;

		@include minW(992) {
			flex: 0 0 auto;
			width: 40%;
			padding: 0 18px;
		}

		@include minW(1600) {
			padding: 0 24px;
		}

		&:last-child {
			@include minW(992) {
				width: 20%;
			}
		}

		.menu {
			position: relative;
			list-style: none;
			padding: 0;
			margin-bottom: 16px;

			@include minW(640) {
				margin-bottom: 24px;
			}

			@include minW(992) {
				margin-bottom: 0;
			}

			&__item {
				margin-bottom: 16px;

				@include minW(640) {
					margin-bottom: 24px;
				}

				@include minW(992) {
					margin-bottom: 16px;
				}

				@include minW(1600) {
					margin-bottom: 24px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			&__link {
				font-size: 14px;
				line-height: 1.1;
				font-weight: 500;
				color: $black-color;
				transition: all 0.3s ease-out;

				@include minW(640) {
					font-size: 16px;
				}

				&:hover {
					color: $red-color;
				}
			}
		}
	}

	&__copy {
		text-align: center;
		font-size: 14px;
		font-weight: 500;
		color: #a6a6a6;
		margin-bottom: 0;

		@include minW(640) {
			font-size: 16px;
		}
	}

	&__foot {
		margin-bottom: 32px;

		@include minW(640) {
			margin-bottom: 64px;
		}

		@include minW(992) {
			margin-bottom: 112px;
			margin-left: 48px;
		}

		.footer {
			&__row {
				@include minW(640) {
					display: flex;
					flex-wrap: wrap;
					margin: 0 -18px;
				}
			}
			&__col {
				@include minW(640) {
					width: 50%;
					flex-basis: 50%;
					padding: 0 18px;
				}

				@include minW(992) {
					width: 25%;
					flex-basis: 25%;
				}
			}
		}
	}
}
// P add u-40 update
.section-40year-head {
	color: #191919;
}

.section-40year-head__info {
	font-weight: 700;
	margin-bottom: 20px;
	text-align: center;
}

.section-40year-head .note {
	padding-bottom: 20px;
	text-align: center;
}

.section-40year-head__link {
	margin-bottom: 40px;
}

.section-40year-head__link .link-list .link-item:not(:last-child) {
	padding-bottom: 20px;
}

.section-40year-head__link .link-list .link-item a {
	display: block;
	img {
		max-width: 100%;
	}
}

.section-40year-head__link .link-list .link-item a:hover {
	opacity: 0.7;
}

.section-40year-head__youtube {
	border-bottom: 1px solid #dbb362;
	margin-bottom: 55px;
	padding-bottom: 55px;
}

.section-40year-head__youtube .thumb-youtube {
	text-align: center;
}
@include minW(992) {
	.section-40year-head {
		font-size: 18px;
	}

	.section-40year-head__link .link-list {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
	}

	.section-40year-head__link .link-list .link-item {
		padding: 0 10px;
	}
}
// Block sponsors
.block-sponsors__title {
	font-weight: 700;
	margin-bottom: 20px;
	text-align: center;
}

.block-sponsors__inner {
	text-align: center;
}

.block-sponsors__box {
	margin-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;
	position: relative;
	z-index: 9;
}

.block-sponsors__box:last-child {
	margin-bottom: 0;
}

.block-sponsors__box:after,
.block-sponsors__box:before {
	background-repeat: no-repeat;
	background-size: contain;
	bottom: 0;
	content: '';
	position: absolute;
	top: 0;
	width: 30px;
}

.block-sponsors__box:before {
	background-position: 0 0, 0 100%;
	left: 0;
}

.block-sponsors__box:after,
.block-sponsors__box:before {
	background-image: url(../img/angle-top.png), url(../img/angle-bottom.png);
}

.block-sponsors__box:after {
	background-position: 100% 0, 100% 100%;
	right: 0;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.block-sponsors__box .sponsors-title {
	color: #a27909;
	font-size: 12px;
	font-weight: 700;
	line-height: 1.58333;
	margin-bottom: 15px;
}

.block-sponsors__box.list-company .block-sponsors__item {
	text-align: left;
}

.block-sponsors__box.list-company .block-sponsors__item .content {
	font-size: 8px;
}

.block-sponsors__content {
	background-color: #fff;
	border-bottom: 1px solid #a27909;
	border-top: 1px solid #a27909;
	padding: 30px 10px;
	text-align: center;
}

.block-sponsors__content:before {
	background-color: #fff;
	border-left: 1px solid #a27909;
	border-right: 1px solid #a27909;
	bottom: 29px;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 29px;
	z-index: -1;
}

.block-sponsors__list {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #ac892b;
	display: grid;
	gap: 10px 20px;
	letter-spacing: 0.66px;
}

.block-sponsors__list.justify-left {
	-webkit-box-pack: start !important;
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}

.block-sponsors__list.logo {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	gap: inherit;
	justify-content: center;
	margin-bottom: -10px;
	margin-left: -20px;
	margin-right: -20px;
}

.block-sponsors__list.logo.grid-3 .item {
	width: 33.333333%;
}

.block-sponsors__list.logo.grid-4 .item {
	width: 25%;
}

.block-sponsors__list.logo.grid-5 .item {
	width: 20%;
}

.block-sponsors__list.logo .item {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

.block-sponsors__list.grid-3 {
	grid-template-columns: 1fr 1fr 1fr;
}

.block-sponsors__list.grid-4 {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.block-sponsors__list.grid-5 {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.block-sponsors__list.medium {
	font-size: 81.818%;
	gap: 40px 70px;
	letter-spacing: 0.54px;
	margin-left: auto;
	margin-right: auto;
	max-width: 80.963%;
}

.block-sponsors__list.small {
	gap: 30px 40px;
	margin-left: auto;
	margin-right: auto;
	max-width: 71.154%;
}

.block-sponsors__list + .block-sponsors__list {
	margin-top: 135px;
}

.block-sponsors__list a {
	color: #ac892b;
}

.block-sponsors__list a:hover {
	opacity: 0.8;
}
// block 40 interview-japanese
.block-40interview {
	color: #333;
	margin: 0 auto;
	max-width: 1040px;
}

.block-40interview__inner {
	margin: 0 auto;
	max-width: 912px;
}

.block-40interview__txt {
	border-bottom: 1px solid #dbb362;
	font-weight: 700;
	margin-bottom: 30px;
	padding-bottom: 25px;
	padding-top: 15px;
	text-align: center;
}

.block-40interview__txt p {
	margin-bottom: 0;
}

.block-40interview #u40-interview {
	padding-bottom: 30px;
}

.block-40interview #u40-interview .splide__track:after,
.block-40interview #u40-interview .splide__track:before,
.block-40interview #u40-interview.splide:after,
.block-40interview #u40-interview.splide:before {
	display: none;
}

.block-40interview #u40-interview .splide__slide .thumb {
	text-align: center;
}

.block-40interview #u40-interview .splide__arrow--prev {
	left: -15px;
}

.block-40interview #u40-interview .splide__arrow--next {
	right: -15px;
}

.block-40interview__participant {
	border: 1px solid #a27a0a;
	border-radius: 14px;
	font-weight: 700;
	margin: 0 auto;
	max-width: 830px;
	padding: 10px 15px;
}

.block-40interview__participant .title {
	color: #a27a0a;
	font-size: 18px;
	padding-bottom: 10px;
	text-align: center;
}

.block-40interview__participant ul {
	margin-bottom: 5px;
}

.block-40interview__participant-name {
	position: relative;
}

.block-40interview__participant-name:before {
	position: absolute;
	content: '';
	top: 10px;
	left: 0;
	width: 5px;
	height: 5px;
	border-radius: 100%;
	background: #333;
}

.block-40interview__participant-thumb {
	padding-top: 10px;
}

.block-40interview__participant-li:not(:last-child) {
	padding-bottom: 15px;
}

.block-40interview__content {
	padding-top: 30px;
}

.block-40interview__content blockquote {
	color: #000;
	font-size: 18px;
	font-weight: 700;
	line-height: 1.47059;
	margin-bottom: 15px;
	padding-left: 21px;
	position: relative;
}

.block-40interview__content blockquote:before {
	background-color: #a27a0a;
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 10px;
}

.block-40interview__content .item {
	padding-bottom: 30px;
}

.block-40interview__content .item .ct-img {
	padding-top: 25px;
	text-align: center;
}

.block-40interview__content .table-ul .table-li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.block-40interview__content .table-ul .table-li:not(:last-child) {
	padding-bottom: 20px;
}

.block-40interview__content .table-ul .table-li .name {
	font-weight: 700;
}

.block-40interview__content .table-ul .table-li .txt {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.block-40interview__content .gr {
	color: #099;
}

.block-40interview__content .bl {
	color: #2442ea;
}

.block-40interview__content .rd {
	color: #e84c07;
}

.block-40interview__profile {
	border-top: 1px solid #dbb362;
	margin-top: 40px;
	padding-top: 25px;
}

.block-40interview__profile .pr-title {
	color: #a27a0a;
	font-size: 38px;
	padding-bottom: 15px;
	text-align: center;
}

.block-40interview__profile .pr-item {
	padding-bottom: 25px;
}

.block-40interview__profile .pr-hl {
	color: #a27a0a;
	font-size: 16px;
	font-weight: 700;
}
@include minW(768) {
	.block-40interview .sp {
		display: none;
	}

	.block-40interview #u40-interview {
		padding-bottom: 58px;
	}

	.block-40interview__participant .title {
		font-size: 26px;
	}

	.block-40interview__participant ul {
		margin-bottom: 8px;
	}

	.block-40interview__participant-li {
		display: flex;
	}

	.block-40interview__participant-name {
		flex-grow: 1;
		position: relative;
	}

	.block-40interview__participant-name:before {
		top: 14px;
		left: -18px;
		width: 6px;
		height: 6px;
	}

	.block-40interview__participant-thumb {
		flex: 0 0 30%;
		padding-left: 30px;
	}

	.block-40interview__content {
		padding-top: 58px;
	}

	.block-40interview__content blockquote {
		font-size: 20px;
		margin-bottom: 35px;
	}

	.block-40interview__content .item {
		padding-bottom: 40px;
	}

	.block-40interview__content .item .ct-img {
		padding-top: 40px;
	}

	.block-40interview__content .table-ul .table-li .name {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 120px;
		flex: 0 0 120px;
	}

	.block-40interview__content .table-ul .table-li .txt {
		padding-left: 20px;
	}

	.block-40interview__content .table-ul .table-li .img {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 209px;
		flex: 0 0 209px;
		padding-left: 20px;
	}

	.block-40interview__profile {
		padding-top: 40px;
	}

	.block-40interview__profile .pr-title {
		padding-bottom: 25px;
	}

	.block-40interview__profile .pr-item {
		padding-bottom: 40px;
	}
}
.block-content1__body {
	iframe,
	video {
		aspect-ratio: 16 / 9;
		width: 100%; /* change this to a fixed width, or create a container with a width. */
		height: 100%;
	}
	.container-iframe {
		position: relative;
		width: 100%;
		overflow: hidden;
		padding-top: 56.25%; /* 16:9 Aspect Ratio */
		.responsive-iframe {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}
}
