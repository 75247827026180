.btn {
	display: inline-flex;
	font-size: 14px;
	line-height: 1;
	font-weight: 600;
	border-radius: 15px;
	padding: 7px 15px;
	transition: all 0.3s ease-out;


	@include minW(640) {
		padding: 14px 30px;
		border-radius: 30px;
		font-size: 18px;
	}

	@include minW(992) {
		padding: 14px 30px;
		border-radius: 30px;
		font-size: 15px;
	}

	@include minW(1600) {
		padding: 14px 30px;
		border-radius: 30px;
		font-size: 20px;
	}

	&--primary {
		border: 1px solid $red-color;
		background-color: $red-color;
		color: $white-color;

		&:hover {
			background-color: $white-color;
			color: $black-color;
		}
	}

	&--secondary {
		border: 1px solid $red-color;
		color: $black-color;
		background-color: $white-color;

		&:hover {
			background-color: $red-color;
			color: $white-color;
		}
	}

	&--link {
		padding: 0;
		color: $red-color;

		&:hover {
			color: $black-color;
		}
	}
}
