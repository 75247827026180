.card {
	position: relative;
	overflow: hidden;
	border-radius: 20px;

	@include minW(640) {
		border-radius: 40px;
	}

	@include minW(992) {
		border-radius: 30px;
	}

	@include minW(1600) {
		border-radius: 40px;
	}

	&__thumb {
		position: relative;
		overflow: hidden;

		img {
			display: block;
			max-width: 100%;
			width: 100%;
			transition: transform 0.3s ease-out;
		}
	}

	&__body {
		background-color: #f1f1f1;
		padding: 16px;
		color: #6b7280;
		transition: all 0.3s ease-out;

		@include minW(640) {
			padding: 32px;
		}

		@include minW(992) {
			padding: 18px 18px 18px;
		}

		@include minW(1600) {
			padding: 24px 48px 32px;
		}
	}

	&__title {
		font-size: 30px;
		line-height: 1.14;
		color: $red-color;

		@include minW(992) {
			font-size: 22px;
		}

		@include minW(1600) {
			font-size: 30px;
		}
	}

	&__desc {
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&--horizontal {
		border-radius: 0;

		@include minW(992) {
			display: flex;
			margin: 0 -24px;
		}

		.card {
			&__thumb {
				@include minW(992) {
					flex: 0 0 auto;
					width: 36.456%;
					padding: 0 24px;
				}
			}

			&__body {
				background-color: transparent;
				padding: 16px 0 0;
				@include minW(992) {
					flex: 0 0 auto;
					width: 63.544%;
					padding: 0 24px;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}
		}

		&:hover {
			.card {
				&__body {
					background-color: transparent !important;
					color: $black-color !important;

					.title,
					.btn {
						color: $black-color !important;
					}
				}
			}
		}
	}

	&--type1 {
		.title {
			margin-bottom: 0;
		}
	}

	&:hover {
		.card {
			&__thumb {
				img {
					transform: scale(1.05);
				}
			}

			&__body {
				background-color: $red-color;
				color: $white-color;

				.title,
				.title a,
				.btn {
					color: $white-color;
				}
			}
		}
	}
}
