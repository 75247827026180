.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $white-color;
	z-index: 99;

	&__logo {
		display: block;
		width: 40px;
		padding: 8px 0;

		@include minW(640) {
			width: 80px;
			padding: 16px 0;
		}

		@include minW(992) {
			padding: 0;
			width: 75px;
			transition: width 0.3s ease-out;
		}

		@include minW(1600) {
			width: 100px;
		}
	}

	&__body {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 0 16px;
		margin: 0 auto;
		height: 50px;
		z-index: 3;

		@include minW(640) {
			padding: 0 24px;
			height: 100px;
		}

		@include minW(992) {
			padding: 0 16px;
			height: 102px;
			transition: height 0.3s ease-out;
		}

		@include minW(1400) {
			max-width: 85%;
		}

		@include minW(1600) {
			height: 135px;
		}
	}

	&__scroll {
		position: fixed;
		top: 51px;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: calc(100% - 51px);
		background-color: $white-color;
		overflow: hidden;
		overflow-y: auto;
		padding-bottom: 16px;
		transition: transform 0.3s ease-out;
		transform: translateX(100%);

		@include minW(640) {
			top: 101px;
			height: calc(100% - 101px);
		}

		@include minW(992) {
			position: relative;
			width: auto;
			height: 100%;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			background-color: transparent;
			overflow: unset;
			padding: 0;
			transform: none;
		}
	}

	&__foot {
		position: relative;
		background-color: $red-color;
		transition: height 0.3s ease-out;
		z-index: 2;

		@include minW(992) {
			overflow: hidden;
		}
	}

	&__extend {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin: 0 auto;
		height: 48px;

		@include minW(640) {
			height: 96px;
		}

		@include minW(992) {
			height: 72px;
			justify-content: flex-end;
			transition: height 0.3s ease-out;
		}

		@include minW(1400) {
			max-width: 85%;
		}

		@include minW(1600) {
			height: 96px;
		}
	}

	&.is-show {
		.header {
			&__scroll {
				transform: translateX(0);
			}
		}
	}

	&.is-move {
		.header {
			&__logo {
				@include minW(992) {
					width: 40px;
				}
			}

			&__body {
				@include minW(992) {
					height: 60px;
				}
			}

			&__extend {
				@include minW(992) {
					height: 60px;
				}
			}
		}

		.subnav {
			@include minW(992) {
				top: 60px;
			}
		}

		&.is-move-hide {
			.header {
				&__foot {
					@include minW(992) {
						height: 0;
					}
				}
			}
		}
	}
}

.nav {
	position: relative;
	list-style: none;
	padding: 0;
	margin-bottom: 0;

	@include minW(992) {
		display: flex;
		height: 100%;
		margin-right: -20px;
	}

	@include minW(1600) {
		margin-right: -28px;
	}

	&__item {
		position: relative;

		&:hover {
			.nav {
				&__link {
					color: $red-color;
				}
			}

			.subnav {
				display: block;
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;
		font-size: 18px;
		color: #6b7280;
		transition: color 0.3s ease-out;
		padding: 16px 16px 8px;

		@include minW(640) {
			font-size: 24px;
			padding: 24px 24px 16px;
		}

		@include minW(992) {
			font-size: 16px;
			padding: 0 18px;
			height: 100%;
		}

		@include minW(1200) {
			font-size: 18px;
			padding: 0 18px;
		}

		@include minW(1600) {
			font-size: 24px;
			padding: 0 28px;
		}
	}
}

.subnav {
	width: 100%;
	list-style: none;
	padding: 0;
	margin-bottom: 0;
	background-color: #fff;

	@include minW(992) {
		display: none;
		position: absolute;
		top: 100px;
		left: 0;
		max-height: calc(100vh - 100px);
		overflow: hidden;
		overflow-y: auto;
		min-width: 28vw;
	}

	@include minW(1600) {
		top: 134px;
		max-height: calc(100vh - 134px);
	}

	&__link {
		display: block;
		font-size: 14px;
		color: $black-color;
		padding: 6px 32px;
		transition: all 0.3s ease-out;

		@include minW(640) {
			font-size: 18px;
			padding: 12px 48px;
		}

		@include minW(992) {
			font-size: 16px;
			border: 1px solid #e2e2e2;
			padding: 15px 20px;
		}

		@include minW(1200) {
			font-size: 18px;
		}

		@include minW(1600) {
			font-size: 25px;
		}

		&:hover {
			background-color: $red-color;
			color: $white-color;
		}
	}
}

.childnav {
	list-style: none;
	padding: 0;

	&__link {
		display: block;
		font-size: 14px;
		color: $black-color;
		padding: 6px 48px;
		transition: all 0.3s ease-out;

		@include minW(640) {
			font-size: 18px;
			padding: 12px 64px;
		}

		@include minW(992) {
			font-size: 16px;
			padding: 15px 40px;
			border: 1px solid #e2e2e2;
		}

		@include minW(1200) {
			font-size: 18px;
		}

		@include minW(1600) {
			font-size: 25px;
		}

		&:hover {
			background-color: $red-color;
			color: $white-color;
		}
	}
}

.childsubnav {
	list-style: none;
	padding: 0;

	&__link {
		display: block;
		font-size: 14px;
		color: $black-color;
		padding: 6px 68px;
		transition: all 0.3s ease-out;

		@include minW(640) {
			font-size: 18px;
			padding: 12px 84px;
		}

		@include minW(992) {
			font-size: 16px;
			padding: 15px 60px;
			border: 1px solid #e2e2e2;
		}

		@include minW(1200) {
			font-size: 18px;
		}

		@include minW(1600) {
			font-size: 25px;
		}

		&:hover {
			background-color: $red-color;
			color: $white-color;
		}
	}
}

.search-box {
	position: relative;
	width: 100%;
	margin-right: 24px;

	@include minW(640) {
		max-width: 430px;
		margin-right: 32px;
	}

	@include minW(992) {
		margin-right: 16px;
		max-width: 322px;
	}

	@include minW(1600) {
		max-width: 430px;
		margin-right: 20px;
	}

	input {
		width: 100%;
		height: 30px;
		font-size: 16px;
		font-weight: 400;
		padding: 4px 16px 4px 32px;
		outline: none;
		border: 1px solid #a6a6a6;
		background-color: #f6ebea;
		display: block;

		@include minW(640) {
			font-size: 18px;
			height: 40px;
			padding: 4px 16px 4px 40px;
		}

		@include minW(992) {
			font-size: 15px;
			height: 24px;
			padding: 4px 16px 4px 30px;
		}

		@include minW(1600) {
			font-size: 20px;
			height: 32px;
			padding: 4px 16px 4px 36px;
		}
	}

	.icon {
		position: absolute;
		top: 4px;
		left: 8px;
		font-size: 20px;
		color: #a6a6a6;

		@include minW(640) {
			font-size: 28px;
		}

		@include minW(992) {
			top: 2px;
			left: 6px;
			font-size: 20px;
		}

		@include minW(1600) {
			font-size: 24px;
			top: 6px;
			left: 8px;
		}
	}
}

.lang {
	position: relative;

	&__current {
		color: $white-color;
		display: flex;
		align-items: center;
		margin-bottom: 0;
		font-size: 14px;

		@include minW(640) {
			font-size: 20px;
		}

		@include minW(992) {
			display: none;
		}

		&::after {
			font-family: $icon-font;
			content: '\e904';
			display: block;
			line-height: 1;
			speak: never;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 20px;

			@include minW(640) {
				font-size: 30px;
			}
		}
	}

	&__list {
		display: none;
		list-style: none;
		padding: 0;
		margin-bottom: 0;
		position: absolute;
		top: 34px;
		right: 0;
		box-shadow: 0 5px 5px rgba($black-color, 0.5);

		@include minW(640) {
			top: 63px;
		}

		@include minW(992) {
			position: relative;
			top: auto;
			right: auto;
			display: flex;
			flex-direction: row;
			box-shadow: none;
		}
	}

	&__link {
		border: 1px solid $white-color;
		display: block;
		color: $black-color;
		background-color: $white-color;
		font-size: 14px;
		padding: 4px 20px;
		text-align: right;

		@include minW(640) {
			font-size: 20px;
			padding: 8px 24px;
		}

		@include minW(992) {
			font-size: 12px;
			height: 24px;
			padding: 3px 8px;
		}

		@include minW(1600) {
			font-size: 14px;
			height: 32px;
			padding: 6px 8px;
		}

		&.is-active {
			background-color: $red-color;
			color: $white-color;
		}
	}

	&.is-show {
		.lang {
			&__current {
				&::after {
					content: '\e903';
				}
			}

			&__list {
				display: block;
			}
		}
	}
}

.toggle {
	position: absolute;
	right: 16px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	border: 0;
	font-size: 32px;
	background-color: transparent;
	outline: 0;

	@include minW(640) {
		font-size: 48px;
		right: 24px;
	}

	@include minW(992) {
		display: none;
	}

	&::before {
		@extend .icon;
		content: '\e905';
	}

	&:hover,
	&:focus {
		outline: 0;
	}

	&.is-show {
		&::before {
			content: '\e901';
		}
	}
}
