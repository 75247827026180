@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
	background: #fff url('./ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face {
	font-weight: normal;
	font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
	font-size: 0;
	line-height: 0;

	position: absolute;
	top: 50%;

	display: block;

	width: 20px;
	height: 20px;
	padding: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);

	cursor: pointer;

	color: transparent;
	border: none;
	outline: none;
	background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	color: transparent;
	outline: none;
	background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
	opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
	font-size: 20px;
	line-height: 1;

	opacity: 0.75;
	color: white;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	left: -25px;
}
[dir='rtl'] .slick-prev {
	right: -25px;
	left: auto;
}
.slick-prev:before {
	content: '←';
}
[dir='rtl'] .slick-prev:before {
	content: '→';
}

.slick-next {
	right: -25px;
}
[dir='rtl'] .slick-next {
	right: auto;
	left: -25px;
}
.slick-next:before {
	content: '→';
}
[dir='rtl'] .slick-next:before {
	content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: -25px;

	display: block;

	width: 100%;
	padding: 0;
	margin: 0;

	list-style: none;

	text-align: center;
}
.slick-dots li {
	position: relative;

	display: inline-block;

	width: 20px;
	height: 20px;
	margin: 0 5px;
	padding: 0;

	cursor: pointer;
}
.slick-dots li button {
	font-size: 0;
	line-height: 0;

	display: block;

	width: 20px;
	height: 20px;
	padding: 5px;

	cursor: pointer;

	color: transparent;
	border: 0;
	outline: none;
	background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
	outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
	opacity: 1;
}
.slick-dots li button:before {
	font-family: 'slick';
	font-size: 6px;
	line-height: 20px;

	position: absolute;
	top: 0;
	left: 0;

	width: 20px;
	height: 20px;

	content: '•';
	text-align: center;

	opacity: 0.25;
	color: black;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
	opacity: 0.75;
	color: black;
}

// Slider
/* Default slide */
.abc-center .slick-slide {
	// background-color: #b32532;
	// height: 300px;
	margin: 0 15px 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: scale(0.9);
	transition: all 0.4s ease-in-out;
}
.abc-center .slick-slide,
.abc-center
	.slick-slide[aria-hidden='true']:not(.slick-cloned)
	~ .slick-cloned[aria-hidden='true'] {
	transform: scale(0.8, 0.8);
	transition: all 0.4s ease-in-out;
}

/* Active center slide (You can change anything here for cenetr slide)*/
.abc-center {
	.arrow {
		border: solid $red-color;
		border-width: 0 3px 3px 0;
		display: inline-block;
		padding: 3px;
		top: 50%;

		display: block;

		width: 20px;
		height: 20px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		position: absolute;
		cursor: pointer;
	}

	.right {
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		right: -25px;
	}

	.left {
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
		left: -25px;
	}
}
.abc-center .slick-center,
.abc-center
	.slick-slide[aria-hidden='true']:not([tabindex='-1'])
	+ .slick-cloned[aria-hidden='true'] {
	transform: scale(1.1);
	background-color: #000000;
}
.abc-center .slick-current.slick-active {
	transform: scale(1.1);
	background-color: #000000;
}

.block-slider__body {
	margin-bottom: 4vw;
	.slick-prev:before,
	.slick-next:before {
		color: $red-color;
	}
	.card__thumb {
		position: relative;
		margin: 0 1vw;
		border-radius: 1vw;
		border-radius: 1vw;

		.content {
			// display: none;
			text-align: center;
			opacity: 0;
			position: absolute;
			bottom: 0;
			// top: 0;
			padding: 0.5vw 0.5vw 1.5vw;
			background-color: rgba(255, 255, 255, 0.8);
		}
		&.slick-current.slick-active {
			.content {
				// display: block;

				opacity: 1;
				translate: all 1s;
			}
		}
	}
}
.highlight {
	img {
		// border: 1px solid $red-color;
		padding: 3px;
		border-radius: 1vw;
	}
	.name {
		color: $red-color;
	}
}
// Style editor
blockquote {
	background: #f9f9f9;
	border-left: 10px solid #ccc;
	margin: 1.5em 10px;
	padding: 0.5em 10px;
	quotes: '\201C''\201D''\2018''\2019';
}
blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}
blockquote p {
	display: inline;
}
.block-content1 {
	iframe {
		max-width: 100%;
	}
	a:hover {
		color: $red-color;
	}
	table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		margin-bottom: 1vw;
		td {
			padding: 2vw;
			background: #f9f9f9;
			border: 0.0625rem solid #ccc;
			@include minW(992) {
				padding: 1vw;
			}
		}
	}
	img,
	video {
		max-width: 100%;
		height: auto;
		&.aligncenter {
			display: block;
			margin: 0 auto;
		}
	}
	dt.title {
		margin-bottom: 0.25vw !important;
	}
}
.page-anio {
	@include minW(992) {
		.container {
			max-width: 70%;
		}
	}
}
// table

.table-event,
.table-event-activity {
	margin-bottom: 30px;
	margin-left: -15px;
	margin-right: -15px;
	overflow-x: auto;
	padding-bottom: 30px;
	p {
		margin-bottom: 0;
	}
	@include minW(992) {
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
		padding-bottom: 0;
	}
	&__head {
		background: #ccc;
		color: $red-color;
	}
	&__content {
		background-color: #fff;
		border-left: 1px solid #707070;
		border-top: 1px solid #707070;
		color: #707070;
		letter-spacing: 0.66px;
		min-width: 820px;
		// text-align: center;
	}
	&__row {
		border-bottom: 1px solid #707070;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		position: relative;
		.date {
			width: 12%;
		}
		.name {
			width: 20%;
		}
		.local,
		.organizer {
			width: 14%;
		}
		.actions {
			-webkit-box-flex: 1;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
		}
	}
	&__col {
		-webkit-box-flex: 0;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		align-items: center;
		border-right: 1px solid #707070;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		// justify-content: center;
		padding: 9px 10px;
		.content {
			word-break: break-word;
		}
	}
	&__body {
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
	}
	.table-event__scroll {
		height: 1px;
		left: 0;
		position: absolute;
		right: 0;
		top: -80px;
	}
}
.table-event-activity {
	margin-bottom: 30px;
	margin-left: -15px;
	margin-right: -15px;
	overflow-x: auto;
	padding-bottom: 30px;
}

.table-event-activity__content {
	background-color: #fff;
	border-left: 1px solid #707070;
	border-top: 1px solid #707070;
	color: #707070;
	letter-spacing: 0.66px;
	min-width: 820px;
}

.table-event-activity__head {
	background: #ccc;
	color: $red-color;
	text-align: center;
}

.table-event-activity__head .table-event-activity__col {
	min-height: 40px;
}

.table-event-activity__body {
	font-size: 14px;
	list-style-type: none;
	margin-bottom: 0;
	padding-left: 0;
}

.table-event-activity__body .table-event__col {
	min-height: 180px;
}

.table-event-activity__row {
	border-bottom: 1px solid #707070;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}

.table-event-activity__scroll {
	height: 1px;
	left: 0;
	position: absolute;
	right: 0;
	top: -80px;
}

.table-event-activity__col {
	-webkit-box-flex: 0;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	align-items: center;
	border-right: 1px solid #707070;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	// justify-content: center;
	padding: 9px 10px;
}

.table-event-activity__col.business-name {
	width: 15%;
}

.table-event-activity__col.place {
	width: 10%;
}

.table-event-activity__col.organizer {
	width: 14%;
}

.table-event-activity__col.business-purpose {
	width: 28%;
}

.table-event-activity__col.business-overview {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.table-event-activity__col .content {
	width: 100%;
}
