.pagination {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	padding: 48px 0;
	margin-bottom: 0;

	.page-item {
		margin: 0 4px;

		&.active {
			.page-link {
				color: $red-color;
				pointer-events: none;
				cursor: default;
			}
		}
	}

	.page-link {
		display: block;
		padding: 4px;
	}
}
