.block-search {
	position: relative;
	overflow: hidden;
	padding: 24px 0;

	@include minW(640) {
		padding: 48px 0;
	}

	@include minW(992) {
		padding: 32px 0;
	}

	@include minW(1600) {
		padding: 48px 0;
	}

	&__head {
		text-align: center;
		margin-bottom: 30px;

		@include minW(640) {
			margin-bottom: 60px;
		}

		@include minW(992) {
			margin-bottom: 60px;
		}

		@include minW(1600) {
			margin-bottom: 80px;
		}
	}

	&__box {
		background: rgba(255, 246, 246, 0.9);
		box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.05);
		border-radius: 20px;
		padding: 16px;
		margin-bottom: 48px;

		@include minW(640) {
			border-radius: 20px;
			padding: 32px;
			margin-bottom: 96px;
		}

		@include minW(992) {
			border-radius: 15px;
			padding: 22.5px;
			margin-bottom: 72px;
		}

		@include minW(1600) {
			border-radius: 20px;
			padding: 30px;
			margin-bottom: 96px;
		}

		.form {
			display: flex;
			align-items: center;
		}

		.btn {
			border-radius: 10px;
		}

		input {
			width: 100%;
			height: 30px;
			font-size: 16px;
			font-weight: 400;
			padding: 4px 8px;
			outline: none;
			border: 1px solid #a6a6a6;
			background-color: #fff;
			display: block;
			margin-right: 16px;
			border-radius: 10px;

			@include minW(640) {
				height: 50px;
				font-size: 16px;
				font-weight: 400;
				padding: 8px 16px;
			}

			@include minW(992) {
				height: 45px;
				font-size: 16px;
				font-weight: 400;
				padding: 8px 16px;
			}

			@include minW(1600) {
				height: 50px;
				font-size: 16px;
				font-weight: 400;
				padding: 8px 16px;
			}
		}
	}

	&__nodata {
		width: 100%;
		max-width: 1090px;
		margin: 0 auto;
		text-align: center;
		padding-bottom: 24px;

		@include minW(640) {
			padding-bottom: 48px;
		}

		@include minW(992) {
			padding-bottom: 32px;
		}

		@include minW(1600) {
			padding-bottom: 48px;
		}
	}

	&__row {
		@include minW(992) {
			flex-wrap: wrap;
			display: flex;
			margin: 0 -12px;
		}
	}

	&__col {
		margin-bottom: 16px;

		@include minW(640) {
			margin-bottom: 24px;
		}

		@include minW(992) {
			flex: 0 0 auto;
			width: 33.33333%;
			padding: 0 12px;
		}

		.card {
			height: 100%;
			display: flex;
			flex-direction: column;

			&__body {
				flex-grow: 2;
			}
		}
	}
	.block-list__body {
		.label-new {
			position: absolute;
			z-index: 2;
			background: #c32314;
			border: 1px solid #c32314;
			color: #fff;
			background-color: #c32314;
			padding: 0.3vw 1.5vw;
			text-transform: uppercase;
		}
		@include minW(992) {
			.block-search__col {
				width: 25%;
			}
		}
	}
	&__foot {
		text-align: center;
		padding: 32px 0;
	}
}
