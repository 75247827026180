.block-type1 {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	padding: 24px 0;

	@include minW(640) {
		padding: 32px 0;
	}

	@include minW(992) {
		padding: 24px 0;
	}

	@include minW(1600) {
		padding: 32px 0;
	}

	&__main {
		width: 100%;
		max-width: 1652px;
		padding: 0 16px;
		margin: 0 auto;

		@include minW(640) {
			padding: 0 24px;
		}

		@include minW(992) {
			padding: 0 16px;
		}
	}

	&__head {
		width: 100%;
		max-width: 960px;
		text-align: center;
		margin: 0 auto;
	}

	&__desc {
		margin-bottom: 24px;

		@include minW(640) {
			margin-bottom: 48px;
		}

		@include minW(992) {
			margin-bottom: 48px;
		}

		@include minW(1600) {
			margin-bottom: 64px;
		}
	}

	&__body {
		position: relative;
	}

	&__row {
		@include minW(992) {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -14px;
		}
	}

	&__col {
		margin-bottom: 16px;

		@include minW(640) {
			margin-bottom: 32px;
		}

		@include minW(992) {
			padding: 0 14px;
			margin-bottom: 0;
		}

		&--big {
			@include minW(992) {
				width: 57.525%;
			}

			.card {
				height: 100%;
				display: flex;
				flex-direction: column;

				&__body {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					flex-grow: 2;
				}

				&__content {
					height: 100%;
				}
			}
		}

		&--small {
			@include minW(992) {
				width: 42.475%;
			}
		}

		&--equal {
			margin-bottom: 32px;

			@include minW(992) {
				width: 50%;
				flex-basis: 50%;
			}

			.card {
				border-radius: 0;

				&__logo {
					display: block;
					overflow: hidden;
					border-radius: 8px;
					position: relative;
					font-size: 16px;

					&--big {
						@include minW(992) {
							max-height: 16vw;
						}

						margin-bottom: 28px;
					}

					&--small {
						margin-bottom: 24px;
						@include minW(640) {
							max-height: 11.5vw;
							margin-bottom: 0;
						}
					}

					&:hover {
						span {
							color: #ffffff;
							background-color: #c32314;
						}

						img {
							transform: scale(1.02);
						}
					}

					span {
						display: block;
						position: absolute;
						bottom: 0;
						right: 0;
						left: 0;
						width: 100%;
						padding: 16px;
						background-color: #f1f1f1;
						color: #c32314;
						transition: all 0.3s ease-out;
						font-weight: 700;
					}

					&:hover {
						img {
							transform: scale(1.05);
						}
					}
				}

				&__row {
					@include minW(640) {
						display: flex;
						flex-wrap: wrap;
						margin: 0 -14px;
					}
				}

				&__col {
					@include minW(640) {
						width: 50%;
						flex-basis: 50%;
						padding: 0 14px;
					}
				}

				img {
					transition: all 0.3s ease-out;
				}
			}
		}
	}

	&__item {
		margin-bottom: 16px;

		@include minW(640) {
			margin-bottom: 28px;
		}

		@include minW(992) {
			margin-bottom: 20px;
		}

		@include minW(1600) {
			margin-bottom: 28px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.card {
			&__desc {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}

.block-type2 {
	position: relative;
	overflow: hidden;
	padding: 24px 0 0;

	@include minW(640) {
		padding: 32px 0 0;
	}

	@include minW(992) {
		padding: 24px 0 0;
	}

	@include minW(1600) {
		padding: 32px 0 0;
	}

	&__wrap {
		border-radius: 30px 30px 0 0;
		padding: 24px 0;
		background: rgba(255, 247, 247, 0.7);
		border: 1px solid #ffffff;
		box-shadow: 4px 4px 50px rgba(107, 114, 128, 0.1);

		@include minW(640) {
			border-radius: 48px 48px 0 0;
			padding: 32px 0;
		}

		@include minW(992) {
			border-radius: 45px 45px 0 0;
			padding: 24px 0;
		}

		@include minW(1600) {
			border-radius: 60px 60px 0 0;
			padding: 32px 0;
		}
	}

	&__main {
		margin: 0 auto;

		@include minW(1600) {
			width: 85%;
		}
	}

	&__head {
		text-align: center;

		@include minW(992) {
			margin: 0 auto;
			max-width: 1000px;
		}
	}

	&__desc {
		margin-bottom: 24px;

		@include minW(640) {
			margin-bottom: 48px;
		}

		@include minW(992) {
			margin-bottom: 48px;
		}

		@include minW(1600) {
			margin-bottom: 64px;
		}
	}

	&__row {
		@include minW(992) {
			display: flex;
			margin: 0 -14px;
		}
	}

	&__col {
		margin-bottom: 16px;

		@include minW(640) {
			margin-bottom: 24px;
		}

		@include minW(992) {
			padding: 0 14px;
			max-width: 33.33333%;
			flex-basis: 33.33333%;
			margin-bottom: 16px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__foot {
		text-align: center;
		padding: 32px 0;
	}
}

.block-type3 {
	position: relative;
	overflow: hidden;
	padding: 24px 0;

	@include minW(640) {
		padding: 32px 0;
	}

	@include minW(992) {
		padding: 24px 0;
	}

	@include minW(1600) {
		padding: 32px 0;
	}

	&__head {
		text-align: center;

		.title {
			margin-bottom: 24px;

			@include minW(640) {
				margin-bottom: 32px;
			}

			@include minW(992) {
				margin-bottom: 60px;
			}

			@include minW(1600) {
				margin-bottom: 80px;
			}
		}
	}

	&__item {
		padding-bottom: 16px;
		margin-bottom: 32px;
		border-bottom: 1px solid $grey-color;

		@include minW(640) {
			padding-bottom: 32px;
			margin-bottom: 32px;
		}

		@include minW(992) {
			padding-bottom: 60px;
			margin-bottom: 60px;
		}

		@include minW(1600) {
			padding-bottom: 80px;
			margin-bottom: 80px;
		}

		&:last-child {
			// margin-bottom: 0;
			padding-bottom: 0;
			border-color: transparent;
		}
	}
}

.block-type4 {
	position: relative;
	overflow: hidden;
	padding: 24px 0;

	@include minW(640) {
		padding: 32px 0;
	}

	@include minW(992) {
		padding: 24px 0;
	}

	@include minW(1600) {
		padding: 32px 0;
	}

	&__wrap {
		max-width: 1740px;
		padding: 0 16px;
		margin: 0 auto;
	}

	&__main {
		background-color: $white-color;
		padding: 16px;
		border-radius: 20px;
		box-shadow: 4px 4px 50px 0px #6b728033;

		@include minW(640) {
			padding: 24px;
			border-radius: 40px;
		}

		@include minW(992) {
			padding: 45px 75px;
			border-radius: 45px;
		}

		@include minW(1600) {
			padding: 60px 100px;
			border-radius: 60px;
		}
	}

	.title {
		text-align: center;
		margin-bottom: 16px;

		@include minW(640) {
			margin-bottom: 24px;
		}

		@include minW(992) {
			margin-bottom: 48px;
		}

		@include minW(1600) {
			margin-bottom: 64px;
		}
	}

	&__row {
		@include minW(992) {
			display: flex;
			align-items: center;
			margin: 0 -40px;
		}
	}

	&__col {
		margin-bottom: 16px;

		@include minW(640) {
			margin-bottom: 24px;
		}

		@include minW(992) {
			flex: 0 0 auto;
			width: 50%;
			padding: 0 40px;
			margin-bottom: 0;
		}
	}

	&--revert {
		.block-type4 {
			&__col {
				order: 1;

				&:last-child {
					order: 0;
				}
			}
		}
	}

	&--link {
		.block-type4 {
			&__content {
				@include minW(992) {
					text-align: right;
				}
			}
		}
	}
}

.block-type5 {
	position: relative;
	overflow: hidden;
	padding: 24px 0;

	@include minW(640) {
		padding: 32px 0;
	}

	@include minW(992) {
		padding: 32px 0;
	}

	@include minW(1600) {
		padding: 48px 0;
	}

	&__body {
		.row {
			margin: 0 -12px 24px;
			align-items: stretch;

			&__col {
				padding: 0 12px;
				margin-bottom: 24px;

				.card {
					@include minW(992) {
						display: flex;
						flex-direction: column;
						height: 100%;
						margin-bottom: 0;
					}

					&__body {
						@include minW(992) {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							flex-grow: 2;
						}
					}

					&--logo {
						border-radius: 0;

						.card {
							&__logo {
								display: block;
								overflow: hidden;
								border-radius: 8px;
								position: relative;
								font-size: 16px;

								&:hover {
									span {
										color: #ffffff;
										background-color: #c32314;
									}

									img {
										transform: scale(1.02);
									}
								}

								span {
									display: block;
									position: absolute;
									bottom: 0;
									right: 0;
									left: 0;
									width: 100%;
									padding: 16px;
									background-color: #f1f1f1;
									color: #c32314;
									transition: all 0.3s ease-out;
									font-weight: 700;
								}

								img {
									transition: all 0.3s ease-out;
								}

								&:hover {
									img {
										transform: scale(1.05);
									}
								}

								&--big {
									@include minW(992) {
										max-height: 16vw;
									}
									margin-bottom: 28px;
								}

								&--small {
									margin-bottom: 24px;
									@include minW(640) {
										max-height: 11.5vw;
										margin-bottom: 0;
									}
								}
							}
						}
					}

					&__row {
						@include minW(640) {
							display: flex;
							flex-wrap: wrap;
							margin: 0 -14px;
						}
					}

					&__col {
						@include minW(640) {
							width: 50%;
							flex-basis: 50%;
							padding: 0 14px;
						}
					}
				}

				&:first-child {
					// margin-bottom: 16px;

					@include minW(640) {
						margin-bottom: 32px;
					}

					// @include minW(992) {
					// 	margin-bottom: 0;
					// }
				}
			}
		}
	}
}
