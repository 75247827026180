.tags {
	position: relative;
	padding: 32px 0 16px;
	background-color: rgba(#FFF6F6, 0.6);

	@include minW(640) {
		padding: 32px 0 24px;
	}

	@include minW(992) {
		padding: 24px 0;
	}

	@include minW(1600) {
		padding: 32px 0;
	}

	&__main {
		width: 100%;
		padding: 0 16px;
		margin: 0 auto;

		@include minW(640) {
			padding: 0 32px;
		}

		@include minW(992) {
			padding: 0 16px;
			max-width: 1282px;
		}
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__item {
		margin-bottom: 16px;
		padding: 0 4px;
		margin-bottom: 16px;

		@include minW(640) {
			padding: 0 8px;
		}

		@include minW(992) {
			padding: 0 8px;
			margin-bottom: 12px;
		}

		@include minW(1600) {
			margin-bottom: 16px;
		}
	}
}
