.block-text1 {
	text-align: center;
	width: 100%;
	max-width: 1180px;
	margin: 0 auto;

	&__desc {
		margin-bottom: 24px;

		@include minW(640) {
			margin-bottom: 48px;
		}

		@include minW(992) {
			margin-bottom: 48px;
		}

		@include minW(1600) {
			margin-bottom: 64px;
		}
	}
}
