.top-banner {
	position: relative;
	padding: 16px 0;
	color: $white-color;

	@include minW(640) {
		padding: 32px 0;
	}

	@include minW(992) {
		padding: 32px 0;
	}

	@include minW(1600) {
		padding: 48px 0;
	}

	&__main {
		position: relative;
		width: 100%;
		min-height: 201px;
		padding: 32px;
		border-radius: 18px;
		display: flex;
		align-items: center;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		@include minW(640) {
			min-height: 405px;
			// padding: 48px;
			// border-radius: 40px;
		}

		@include minW(992) {
			min-height: 670px;
			// padding: 67.5px;
			// border-radius: 30px;
		}

		@include minW(1600) {
			min-height: 900px;
			// padding: 90px;
			// border-radius: 40px;
		}

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			// background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			// background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			// background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			// filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00ffffff', GradientType=1);
		}
	}

	&__body {
		position: relative;
		z-index: 2;
	}

	&__content {
		@include minW(992) {
			max-width: 562px;
		}

		@include minW(1600) {
			max-width: 750px;
		}
	}

	&--video {
		.top-banner {
			&__main {
				// background-color: $black-color;

				video {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					top: 0;
					display: block;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			&__body {
				width: 100%;
				height: 100%;
			}
		}
	}
}
