.icon {
	font-family: $icon-font !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&--arrow-left:before {
		content: "\e906";
	}

	&--arrow-right:before {
		content: "\e907";
	}

	&--double-arrow-left:before {
		content: "\e908";
	}

	&--double-arrow-right:before {
		content: "\e909";
	}

	&--close:before {
		content: "\e901";
	}

	&--done:before {
		content: "\e902";
	}

	&--expand-less:before {
		content: "\e903";
	}

	&--expand-more:before {
		content: "\e904";
	}

	&--menu:before {
		content: "\e905";
	}

	&--search:before {
		content: "\e900";
	}
}
