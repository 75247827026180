.m-0 {
	margin: 0;
}

.m-1 {
	margin: 4px;
}

.m-2 {
	margin: 8px;
}

.m-3 {
	margin: 16px;
}

.m-4 {
	margin: 24px;
}

.m-5 {
	margin: 32px;
}

.m-6 {
	margin: 48px;
}

.mx-0 {
	margin-left: 0;
	margin-right: 0;
}

.mx-1 {
	margin-left: 4px;
	margin-right: 4px;
}

.mx-2 {
	margin-left: 8px;
	margin-right: 8px;
}

.mx-3 {
	margin-left: 16px;
	margin-right: 16px;
}

.mx-4 {
	margin-left: 32px;
	margin-right: 32px;
}

.mx-5 {
	margin-left: 48px;
	margin-right: 48px;
}

.my-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.my-1 {
	margin-top: 4px;
	margin-bottom: 4px;
}

.my-2 {
	margin-top: 8px;
	margin-bottom: 8px;
}

.my-3 {
	margin-top: 16px;
	margin-bottom: 16px;
}

.my-4 {
	margin-top: 32px;
	margin-bottom: 32px;
}

.my-5 {
	margin-top: 48px;
	margin-bottom: 48px;
}
