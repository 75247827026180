.block-content1 {
	position: relative;
	overflow: hidden;
	padding: 24px 0;

	@include minW(640) {
		padding: 48px 0;
	}

	@include minW(992) {
		padding: 32px 0;
	}

	@include minW(1600) {
		padding: 48px 0;
	}

	&__body {
		@include minW(992) {
			width: 85%;
			margin: 0 auto;
		}

		.title {
			text-align: center;
			margin-bottom: 24px;

			@include minW(640) {
				margin-bottom: 48px;
			}

			@include minW(992) {
				margin-bottom: 48px;
			}

			@include minW(1600) {
				margin-bottom: 64px;
			}
		}

		p,
		img {
			margin-bottom: 24px;

			@include minW(640) {
				margin-bottom: 48px;
			}

			@include minW(992) {
				margin-bottom: 48px;
			}

			@include minW(1600) {
				margin-bottom: 64px;
			}

		}
	}

	&__row {

		@include minW(992) {
			display: flex;
			align-items: center;
			margin: 0 -32px;
		}
	}

	&__col {
		@include minW(992) {
			flex: 0 0 auto;
			width: 50%;
			padding: 0 32px;
			text-align: justify;
		}
	}
}
