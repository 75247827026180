.embed {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;

	&--21by9::before {
		padding-top: 42.857143%;
	}

	&--16by9::before {
		padding-top: 56.25%;
	}

	&--4by3::before {
		padding-top: 75%;
	}

	&--1by1::before {
		padding-top: 100%;
	}

	&::before {
		display: block;
		content: '';
	}

	&__item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}
